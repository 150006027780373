
import { defineComponent, PropType } from "vue";
import { NDataTable } from "naive-ui";
import { h } from "@vue/runtime-core";
import { SaleAnimal } from "@/models/sale/sale-animal";
import DetailSale from "./DetailSale.vue";
import { dateTimeFilter, underlineToSpace } from "@/filters/custom-filters";

const createColumns = () => {
  return [
    {
      type: "expand",
      expandable: () => true,
      renderExpand: (sale: SaleAnimal) => {
        return h(DetailSale, { sale });
      },
    },
    {
      title: "Data",
      key: "dataCriacao",
      sorter: "default",
      defaultSortOrder: "descend",
      render: (sale: SaleAnimal) => dateTimeFilter(sale?.dataCriacao),
    },
    {
      title: "Origem",
      key: "proprietarioOrigem.nome",
      sorter: "default",
    },
    {
      title: "Destino",
      key: "proprietarioDestino?.nome",
      sorter: "default",
    },
    {
      title: "Status",
      key: "status",
      sorter: "default",
      render: (sale: SaleAnimal) => underlineToSpace(sale?.status),
    },
  ];
};

const TabSaleAnimal = defineComponent({
  components: { NDataTable },
  props: {
    vendaAtual: SaleAnimal,
    vendaHistorico: { type: Array as PropType<Array<SaleAnimal>> },
  },
  data() {
    return {
      columns: createColumns(),
    };
  },
  methods: {
    dateTimeFilter,
    underlineToSpace,
  },
})
export default TabSaleAnimal;
