
import {
  dateTimeFilter,
  weightFilter,
} from "@/filters/custom-filters";
import { Weight } from "@/models/weight/weight.model";
import { defineComponent } from "vue";

const DetailPesagem = defineComponent({
  props: {
    pesagem: Weight,
  },
  methods: {
    dateTimeFilter,
    weightFilter,
  },
})
export default DetailPesagem;
