
import { defineComponent } from "vue";
import {
  dateFilter,
  dateTimeFilter,
  booleanFilter,
} from "@/filters/custom-filters";
import { Nutrition } from "@/models/nutrition/nutrition.model";
import GlobalEvent from "@/common/global-event";

const DetailNutrition = defineComponent({
  props: {
    nutrition: Object as () => Nutrition,
  },
  methods: {
    dateFilter,
    dateTimeFilter,
    booleanFilter,
    showNutritionInfo(nutrition: any) {
      GlobalEvent.emit("infoDialog", {
        title: null,
        fields: [
          { key: "idBlockchain", label: "ID Blockchain" },
          { key: "nome", label: "Nome" },
          { key: "categoria", label: "Categoria" },
          { key: "subCategoria", label: "Sub-Categoria" },
          { key: "fabricante", label: "Fabricante" },
          { key: "unidade", label: "Unidade" },
          { key: "dataCriacao", label: "Data Criação" },
          { key: "dataAtualizacao", label: "Data Atualização" },
        ],
        data: nutrition,
      });
    },
  },
})
export default DetailNutrition;
