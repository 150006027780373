
import { dateTimeFilter, dateFilter } from "@/filters/custom-filters";
import { PropType } from "vue";
import { defineComponent } from "vue";
import { Localization } from "@/models/localization/localization.model";

const DetailLocalizacao = defineComponent({
  props: {
    localizacao: Object as PropType<Localization>,
    hideItems: { type: Array as PropType<Array<string>>, default: [] },
  },
  methods: {
    dateTimeFilter,
    dateFilter,
    canShowItem: function (name: string) {
      return !this.hideItems.includes(name);
    },
  },
});
export default DetailLocalizacao;
