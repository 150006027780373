
import { NDataTable, NButton } from "naive-ui";
import { h } from "@vue/runtime-core";
import { defineComponent } from "vue";
import DetailAcao from "@/components/animal/DetailAcao.vue";
import { Action } from "@/models/action/action.model";
import { dateFilter, weightFilter } from "../../filters/custom-filters";
import GlobalEvent from "@/common/global-event";

const createColumns = () => {
  return [
    // {
    //   type: "selection",
    // },
    {
      type: "expand",
      expandable: () => true,
      renderExpand: (action: Action) => {
        return h(DetailAcao, { action });
      },
    },
    {
      title: "Efetivado em",
      key: "dtAcao",
      sorter: "default",
      defaultSortOrder: "descend",
      render: (action: Action) => dateFilter(action.dtAcao),
    },
    {
      title: "Nome",
      key: "nome",
      sorter: "default",
    },
    {
      title: "Responsável",
      key: "nomeResponsavel",
      sorter: "default",
    },
    {
      title: "Ações",
      key: "acoes",
      render(action: Action) {
        return [
          h(
            NButton,
            {
              size: "small",
              onClick: () => {
                GlobalEvent.emit("dialog-batch", action.idLoteSync);
              },
              text: true,
              title: "Visualizar as informações do lote",
            },
            h("i", {
              class: "bi bi-box-seam p-1 safe-icon-yellow",
            })
          ),
        ];
      },
    },
  ];
};

const TabAcao = defineComponent({
  components: {
    NDataTable,
  },
  props: {
    acoes: { type: Array as () => Array<Action> },
  },
  data() {
    return {
      columns: createColumns(),
    };
  },
  methods: {},
})
export default TabAcao;
