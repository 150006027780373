
import { Medicine } from "@/models/medicine/medicine.model";
import { defineComponent } from "vue";
import {
  dateFilter,
  dateTimeFilter,
  booleanFilter,
} from "@/filters/custom-filters";
import GlobalEvent from "@/common/global-event";
import { AnimalMedicine } from "@/models/animal-medicine/animal-medicine.model";

const DetailMedicine = defineComponent({
  props: {
    medicine: Object as () => AnimalMedicine,
  },
  methods: {
    dateFilter,
    dateTimeFilter,
    booleanFilter,
    showMedicineInfo(medicine: Medicine) {
      GlobalEvent.emit("infoDialog", {
        title: null,
        fields: [
          { key: "idBlockchain", label: "ID Blockchain" },
          { key: "nome", label: "Nome" },
          { key: "categoria", label: "Categoria" },
          { key: "subCategoria", label: "Sub-Categoria" },
          { key: "fabricante", label: "Fabricante" },
          { key: "unidade", label: "Unidade" },
          { key: "sexo", label: "Sexo" },
          { key: "idadeMinima", label: "Idade Mínima" },
          { key: "idadeMaxima", label: "Idade Máxima" },
          { key: "carenciaDias", label: "Carência Dias" },
          { key: "dose", label: "Dose" },
          { key: "dosePorPeso", label: "Dose Por Peso" },
          { key: "peso", label: "Peso" },
        ],
        data: medicine,
      });
    },
  },
});
export default DetailMedicine;
