
import {
  dateFilter,
  dateTimeFilter,
  weightFilter
} from "@/filters/custom-filters";
import { Action } from "@/models/action/action.model";
import { defineComponent } from "vue";

const DetailAcao = defineComponent({
  props: {
    action: Action,
  },
  methods: {
    dateFilter,
    dateTimeFilter,
		weightFilter
  },
})
export default DetailAcao;
