export class BaseWeight {
     idResponsavel?: string;
     nomeResponsavel?: string;
     latitude?: string;
     longitude?: string;
     tipo?: number;
     peso?: number;
     dtPesagem?: string;
}

export class Weight extends BaseWeight {
     idLoteSync?: string;
     idPesagemBlockchain?: string;
     dtCriacao?: string;
     deletado?: boolean;
     editado?: boolean;
}