
import { defineComponent } from "vue";
import {
  dateFilter,
  dateTimeFilter,
  booleanFilter,
  underlineToSpace,
} from "@/filters/custom-filters";
import { Reproduction } from "@/models/reproduction/reproduction.model";

const DetailReproduction = defineComponent({
  props: {
    reproduction: Reproduction,
  },
  methods: {
    dateFilter,
    dateTimeFilter,
    booleanFilter,
    underlineToSpace,
  },
})
export default DetailReproduction;
