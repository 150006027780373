import { ExamType } from "./exam-type.enum";

export class BaseExam {
    idResponsavel?: string;
    nomeResponsavel?: string;
    latitude?: string;
    longitude?: string;
    tipoExame?: ExamType;
    dtExame?: Date;
    vrResultado?: string;
    vrResultadoAol?: string;
    vrResultadoEgs?: string;
    vrResultadoEgg?: string;
    vrResultadoMar?: string;
    vrResultadoRatio?: string;
    qtDiasPrenhe?: number;
    icApresentouCio?: boolean;
}

export class Exam extends BaseExam {
    idLoteSync?: string;
    idExameBlockchain?: string;
    dtCriacao?: Date;
    dtAlteracao?: Date;
    deletado?: boolean;
    editado?: boolean;
}