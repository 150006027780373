
import {
  dateFilter,
  dateTimeFilter,
  underlineToSpace,
} from "@/filters/custom-filters";
import { SaleAnimal } from "@/models/sale/sale-animal";
import { defineComponent } from "vue";

const DetailSale = defineComponent({
  props: {
    sale: SaleAnimal,
  },
  methods: {
    dateFilter,
    dateTimeFilter,
    underlineToSpace,
  },
})
export default DetailSale;
