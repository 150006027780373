import { AnimalMedicine } from "../animal-medicine/animal-medicine.model";
import { Exam } from "../exam/exam.model";
import { Localization } from "../localization/localization.model";
import { Reproduction } from "../reproduction/reproduction.model";
import { Weight } from "../weight/weight.model";
import { AnimalFridge } from "./animal-fridge.model";
import { AnimalGender } from "./genre.enum";
import { RacialComposition } from "./racial-composition.model";
import { Status } from "./status.enum";
import { Batch } from '@/models/batch/batch.model';
import { Nutrition } from '../nutrition/nutrition.model';
import { SaleAnimal } from "../sale/sale-animal";

export class Animal {
    idLoteSync?: string;
    idBlockchain?: string;
    docType?: string;
    idExterno?: string;
    idSisbov?: string;
    idICar?: string;
    nome?: string;
    dataNascimento?: Date;
    sexo?: AnimalGender;
    idPaiBlockchain?: string;
    idMaeBlockchain?: string;
    idBrincoEletronico?: string;
    idAnimalFazenda?: string;
    idRgn?: string;
    idRgd?: string;
    pelagem?: string;
    composicaoRacial?: Array<RacialComposition>;
    pesoAtual?: Weight;
    pesoHistorico?: Array<Weight>;
    idProdutorAtual?: string;
    produtorAtual?: any;
    status?: Status;
    dataCriacao?: Date;
    dataUltimaAtualizacao?: Date;
    idVendaAtual?: string;
    vendaHistorico?: string[];
    reproducoes?: Reproduction[];
    acoes?: string[];
    exames?: Exam[];
    medicamentosAplicados?: Array<AnimalMedicine>;
    nutricoesAplicadas?: Array<Nutrition>;
    vendaAtual?: SaleAnimal;
    localizacaoAtual?: Localization;
    localizacaoHistorico?: Array<Localization>;
    loteAtual?: Batch;
    loteHistorico?: Array<Batch>;
    frigorifico?: AnimalFridge;
}