
import { defineComponent } from "vue";
import { NDataTable } from "naive-ui";
import { Animal } from "@/models/animal/animal.model";
import {
  dateFilter,
  currencyFilter,
  weightFilter,
  booleanFilter,
} from "@/filters/custom-filters";

const createColumns = () => {
  return [
    {
      title: "Nome",
      key: "nome",
      sorter: "default",
    },
    {
      title: "Porcentagem",
      key: "porcentagemRaca",
      sorter: "default",
    },
    {
      title: "Raça Principal",
      key: "racaPrincipal",
      sorter: "default",
      render(row: any) {
        return booleanFilter(row.racaPrincipal);
      },
    },
  ];
};

const TabInformacao = defineComponent({
  props: {
    animal: Animal,
  },
  methods: {
    dateFilter,
    currencyFilter,
    weightFilter,
  },
  components: { NDataTable },
  data() {
    return { columns: createColumns() };
  },
})
export default TabInformacao;
