export class BaseReproduction {
    idResponsavel?: string;
    nomeResponsavel?: string;
    latitude?: string;
    longitude?: string;
    tipoReproducao?: string;
    descricao?: string;
    dtReproducao?: Date;
}

export class Reproduction extends BaseReproduction {
    idLoteSync?: string;
    idReproducaoBlockchain?: string;
    dtCriacao?: Date;
    dtAlteracao?: Date;
    deletado?: boolean;
    editado?: boolean;
    tipo?: string;
}