
import { defineComponent, PropType } from "vue";
import { NButton, NDataTable } from "naive-ui";
import { h } from "@vue/runtime-core";
import { Reproduction } from "@/models/reproduction/reproduction.model";
import { dateFilter, underlineToSpace } from "@/filters/custom-filters";
import DetailReproduction from "./DetailReproduction.vue";
import GlobalEvent from "@/common/global-event";

const createColumns = () => {
  return [
    {
      type: "expand",
      expandable: () => true,
      renderExpand: (reproduction: any) => {
        return h(DetailReproduction, { reproduction });
      },
    },
    {
      title: "Data",
      key: "dtReproducao",
      sorter: "default",
      defaultSortOrder: "descend",
      render(row: any) {
        return dateFilter(row.dtReproducao);
      },
    },
    {
      title: "Tipo de Reprodução",
      key: "tipo",
      sorter: "default",
      render(row: any) {
        return underlineToSpace(row.tipo);
      },
    },
    {
      title: "Responsável",
      key: "nomeResponsavel",
      sorter: "default",
    },
    {
      title: "Descrição",
      key: "descricao",
      sorter: "default",
    },
    {
      title: "Ações",
      key: "acoes",
      render(reproduction: Reproduction) {
        return [
          h(
            NButton,
            {
              size: "small",
              onClick: () => {
                GlobalEvent.emit("dialog-batch", reproduction.idLoteSync);
              },
              text: true,
              title: "Visualizar as informações do lote",
            },
            h("i", {
              class: "bi bi-box-seam p-1 safe-icon-yellow",
            })
          ),
        ];
      },
    },
  ];
};

const TabReproduction = defineComponent({
  props: {
    reproductions: { type: Array as PropType<Array<Reproduction>> },
  },
  methods: {
    dateFilter,
    underlineToSpace,
  },
  components: { NDataTable },
  data() {
    return { columns: createColumns() };
  },
})
export default TabReproduction;
