
import { NButton, NDataTable } from "naive-ui";
import { dateTimeFilter, dateFilter } from "@/filters/custom-filters";
import { h, PropType } from "vue";
import { defineComponent } from "vue";
import { Medicine } from "@/models/medicine/medicine.model";
import DetailMedicine from "./DetailMedicine.vue";
import GlobalEvent from "@/common/global-event";
import { AnimalMedicine } from "@/models/animal-medicine/animal-medicine.model";

const createColumns = () => {
  return [
    {
      type: "expand",
      expandable: () => true,
      renderExpand: (medicine: AnimalMedicine) => {
        return h(DetailMedicine, { medicine });
      },
    },
    {
      title: "Data",
      key: "dataMedicacao",
      sorter: "default",
      defaultSortOrder: "descend",
      render: (medicine: any) => dateFilter(medicine?.dtMedicacao),
    },
    {
      title: "Responsável",
      key: "nomeResponsavel",
      sorter: "default",
    },
    {
      title: "Descrição",
      key: "descricao",
      sorter: "default",
    },
    {
      title: "Ações",
      key: "acoes",
      render(medicine: AnimalMedicine) {
        return [
          h(
            NButton,
            {
              size: "small",
              onClick: () => {
                GlobalEvent.emit("dialog-batch", medicine.idLoteSync);
              },
              text: true,
              title: "Visualizar as informações do lote",
            },
            h("i", {
              class: "bi bi-box-seam p-1 safe-icon-yellow",
            })
          ),
        ];
      },
    },
  ];
};

const TabMedicine = defineComponent({
  components: { NDataTable },
  data() {
    return {
      columns: createColumns(),
    };
  },
  props: {
    medicineHistory: Array as PropType<Array<Medicine>>,
  },
  methods: { dateTimeFilter, dateFilter },
})
export default TabMedicine;
