
import { defineComponent } from 'vue';
import { Options, Vue } from "vue-class-component";
import { Animal } from "@/models/animal/animal.model";
import HeaderContent from "@/components/template/HeaderContent.vue";
import TabInformacao from "../components/animal/TabInformacao.vue";
import TabAcao from "@/components/animal/TabAcao.vue";
import TabPesagem from "@/components/animal/TabPesagem.vue";
import TabSaleAnimal from "@/components/animal/TabSaleAnimal.vue";
import TabExam from "@/components/animal/TabExam.vue";
import TabLocalizacao from "@/components/animal/TabLocalizacao.vue";
import TabMedicine from "@/components/animal/TabMedicine.vue";
import TabReproduction from "@/components/animal/TabReproduction.vue";
import TabNutrition from "@/components/animal/TabNutrition.vue";
import TabFridge from "@/components/animal/TabFridge.vue";
import TabBatch from "@/components/animal/TabBatch.vue";
import animalService from '@/services/animal.service';

const AnimalDetail = defineComponent({
  data() {
    return {
      loadingTable: true,
      animal: {} as Animal,
      titlePage: "Ver Animal",
    };
  },
  components: {
    HeaderContent,
    TabAcao,
    TabPesagem,
    TabInformacao,
    TabExam,
    TabLocalizacao,
    TabSaleAnimal,
    TabMedicine,
    TabReproduction,
    TabNutrition,
    TabFridge, 
    TabBatch
  },
  props: {
    idAnimal: { type: String },
  },
  methods: {
  },
  beforeMount() {

    const idAnimal = this.$route.params.idAnimal as string;

    if (this.$route.params.animal) {
      this.animal = JSON.parse(this.$route.params.animal as string);
      this.titlePage = this.$route.params.titlePage as string;
    } else if (idAnimal) {
      animalService.getAnimalById(idAnimal).then(r => {
        this.animal = r.data;
      })
    }
  },
})
export default AnimalDetail;
