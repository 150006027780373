
import GlobalEvent from "@/common/global-event";
import DetailPesagem from "@/components/animal/DetailPesagem.vue";
import { dateTimeFilter, weightFilter } from "@/filters/custom-filters";
import { Weight } from "@/models/weight/weight.model";
import { h } from "@vue/runtime-core";
import { NButton, NDataTable } from "naive-ui";
import { defineComponent, PropType } from "vue";

const createColumns = () => {
  return [
    {
      type: "expand",
      expandable: () => true,
      renderExpand: (pesagem: Weight) => {
        return h(DetailPesagem, { pesagem });
      },
    },
    {
      title: "Data",
      key: "dtPesagem",
      sorter: "default",
      defaultSortOrder: "descend",
      render: (pesagem: Weight) => dateTimeFilter(pesagem.dtPesagem),
    },
    {
      title: "Tipo",
      key: "tipo",
      sorter: "default",
    },
    {
      title: "Peso",
      key: "peso",
      sorter: "default",
      render: (pesagem: any) => weightFilter(pesagem.peso),
    },
    {
      title: "Responsável",
      key: "nomeResponsavel",
      sorter: "default",
    },
    {
      title: "Ações",
      key: "acoes",
      render(pesagem: Weight) {
        return [
          h(
            NButton,
            {
              size: "small",
              onClick: () => {
                GlobalEvent.emit("dialog-batch", pesagem.idLoteSync);
              },
              text: true,
              title: "Visualizar as informações do lote",
            },
            h("i", {
              class: "bi bi-box-seam p-1 safe-icon-yellow",
            })
          ),
        ];
      },
    },
  ];
};

const createPesoAtualColumns = () => {
  const columns = createColumns();

  columns.forEach((col) => {
    delete col["defaultSortOrder"];
    delete col["sorter"];

    return col;
  });

  return columns;
};

const TabPesagem = defineComponent({
  components: { NDataTable },
  props: {
    pesoAtual: Object as PropType<Weight>,
    pesoHistorico: { type: Array as PropType<Array<Weight>> },
  },
  data() {
    return {
      columns: createColumns(),
      pesoAtualColumns: createPesoAtualColumns(),
    };
  },
  methods: {
    dateTimeFilter,
    weightFilter,
  },
})
export default TabPesagem;
