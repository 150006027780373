
import { NButton, NDataTable } from "naive-ui";
import { dateTimeFilter, dateFilter } from "@/filters/custom-filters";
import { h, PropType } from "vue";
import { defineComponent } from "vue";
import { Nutrition } from "@/models/nutrition/nutrition.model";
import DetailNutrition from "./DetailNutrition.vue";
import GlobalEvent from "@/common/global-event";

const createColumns = () => {
  return [
    {
      type: "expand",
      expandable: () => true,
      renderExpand: (nutrition: Nutrition) => {
        return h(DetailNutrition, { nutrition });
      },
    },
    {
      title: "Data",
      key: "dtNutricao",
      sorter: "default",
      defaultSortOrder: "descend",
      render: (nutrition: any) => dateFilter(nutrition?.dtNutricao),
    },
    {
      title: "Responsável",
      key: "nomeResponsavel",
      sorter: "default",
    },
    {
      title: "Descrição",
      key: "descricao",
      sorter: "default",
    },
    {
      title: "Ações",
      key: "acoes",
      render(nutrition: Nutrition) {
        return [
          h(
            NButton,
            {
              size: "small",
              onClick: () => {
                GlobalEvent.emit("dialog-batch", nutrition.idLoteSync);
              },
              text: true,
              title: "Visualizar as informações do lote",
            },
            h("i", {
              class: "bi bi-box-seam p-1 safe-icon-yellow",
            })
          ),
        ];
      },
    },
  ];
};

const TabNutrition = defineComponent({
  components: { NDataTable },
  data() {
    return {
      columns: createColumns(),
    };
  },
  props: {
    appliedNutritions: Array as PropType<Array<Nutrition>>,
  },
  methods: { dateTimeFilter, dateFilter },
})
export default TabNutrition;
