export class Action {
  idLoteSync?: string;
  dtAcao?: string;
  dtAlteracao?: string;
  dtCriacao?: string;
  idAcaoBlockchain?: string;
  idResponsavel?: string;
  nomeResponsavel?: string;
  latitude?: string;
  longitude?: string;
  nome?: string;
}