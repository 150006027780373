
import { dateTimeFilter, dateFilter } from "@/filters/custom-filters";
import { Batch } from "@/models/batch/batch.model";
import { PropType, defineComponent } from "vue";

const DetailBatch = defineComponent({
  props: {
    batch: Object as PropType<Batch>,
    hideItems: {
      type: Array as PropType<Array<string>>,
      default: [],
    },
  },
  methods: {
    dateTimeFilter,
    dateFilter,
    canShowItem: function (name: string) {
      return !this.hideItems.includes(name);
    },
  },
});

export default DetailBatch;
