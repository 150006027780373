
import { NButton, NDataTable } from "naive-ui";
import { dateTimeFilter } from "@/filters/custom-filters";
import { h, defineComponent, PropType } from "vue";
import {
  BaseLocalication,
  Localization,
} from "@/models/localization/localization.model";
import DetailLocalizacao from "./DetailLocalizacao.vue";
import GlobalEvent from "@/common/global-event";

const createColumns = (hideItems: Array<string> = []) => {
  return [
    {
      type: "expand",
      expandable: () => true,
      renderExpand: (localizacao: Localization) => {
        return h(DetailLocalizacao, { localizacao, hideItems });
      },
    },
    {
      title: "Data Criação",
      key: "dtCriacao",
      sorter: "default",
      defaultSortOrder: "descend",
      render: (localizacao: any) => dateTimeFilter(localizacao?.dtCriacao),
    },
    {
      title: "Fazenda",
      key: "fazenda",
      sorter: "default",
      render: (localizacao: BaseLocalication) => localizacao?.fazenda?.nome,
    },
    {
      title: "Subdivisão",
      key: "subdivisao",
      sorter: "default",
      render: (localizacao: BaseLocalication) => localizacao?.subdivisao?.nome,
    },
    {
      title: "Ações",
      key: "acoes",
      render(localizacao: Localization) {
        return [
          h(
            NButton,
            {
              size: "small",
              onClick: () => {
                GlobalEvent.emit("dialog-batch", localizacao.idLoteSync);
              },
              text: true,
              title: "Visualizar as informações do lote",
            },
            h("i", {
              class: "bi bi-box-seam p-1 safe-icon-yellow",
            })
          ),
        ];
      },
    },
  ];
};

const TabLocalizacao = defineComponent({
  components: { NDataTable, NButton },
  data() {
    return {
      columns: createColumns(),
    };
  },
  props: {
    localizacaoAtual: Object as PropType<Localization>,
    localizacaoHistorico: Object as PropType<Array<Localization>>,
  },
  methods: { dateTimeFilter },
})
export default TabLocalizacao;
