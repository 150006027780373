
import { Exam } from "@/models/exam/exam.model";
import { defineComponent } from "vue";
import {
  dateFilter,
  dateTimeFilter,
  booleanFilter,
} from "@/filters/custom-filters";

const DetailExam = defineComponent({
  props: {
    exam: Exam,
  },
  methods: {
    dateFilter,
    dateTimeFilter,
    booleanFilter,
  },
})
export default DetailExam;
