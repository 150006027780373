
import { NButton, NDataTable } from "naive-ui";
import { dateTimeFilter } from "@/filters/custom-filters";
import { h, PropType } from "vue";
import { Batch } from "@/models/batch/batch.model";
import { defineComponent } from "vue";
import DetailBatch from "./DetailBatch.vue";
import GlobalEvent from "@/common/global-event";

const createColumns = (hideItems: Array<string> = []) => {
  return [
    {
      type: "expand",
      expandable: () => true,
      renderExpand: (batch: Batch) => {
        return h(DetailBatch, { batch, hideItems });
      },
    },
    {
      title: "Data Criação",
      key: "dtCriacao",
      sorter: "default",
      defaultSortOrder: "descend",
      render: (localizacao: any) => dateTimeFilter(localizacao?.dtCriacao),
    },
    {
      title: "Lote",
      key: "lote",
      sorter: "default",
      render: (batch: Batch) => batch.nome,
    },

    {
      title: "Ações",
      key: "acoes",
      render(batch: Batch) {
        return [
          h(
            NButton,
            {
              size: "small",
              onClick: () => {
                GlobalEvent.emit("dialog-batch", batch.idLoteSync);
              },
              text: true,
              title: "Visualizar as informações do lote",
            },
            h("i", {
              class: "bi bi-box-seam p-1 safe-icon-yellow",
            })
          ),
        ];
      },
    },
  ];
};

const TabBatch = defineComponent({
  components: { NDataTable, NButton },
  data() {
    return {
      columns: createColumns(),
    };
  },
  props: {
    loteAtual: Object as PropType<Batch>,
    loteHistorico: Object as PropType<Array<Batch>>,
  },
  methods: { dateTimeFilter },
});

export default TabBatch;
