
import GlobalEvent from "@/common/global-event";
import { dateFilter, weightFilter } from "@/filters/custom-filters";
import { Exam } from "@/models/exam/exam.model";
import { h } from "@vue/runtime-core";
import { NButton, NDataTable } from "naive-ui";
import { defineComponent } from "vue";
import DetailExam from "./DetailExam.vue";

const createColumns = () => {
  return [
    {
      type: "expand",
      expandable: () => true,
      renderExpand: (exam: any) => {
        return h(DetailExam, { exam });
      },
    },
    {
      title: "Data",
      key: "dtExame",
      sorter: "default",
      defaultSortOrder: "descend",
      render(row: any) {
        return dateFilter(row.dtExame);
      },
    },
    {
      title: "Tipo de Exame",
      key: "tipoExame",
      sorter: "default",
    },
    {
      title: "Responsável",
      key: "nomeResponsavel",
      sorter: "default",
    },
    {
      title: "Resultado",
      key: "vrResultado",
      sorter: "default",
    },
    {
      title: "Ações",
      key: "acoes",
      render(exam: Exam) {
        return [
          h(
            NButton,
            {
              size: "small",
              onClick: () => {
                GlobalEvent.emit("dialog-batch", exam.idLoteSync);
              },
              text: true,
              title: "Visualizar as informações do lote",
            },
            h("i", {
              class: "bi bi-box-seam p-1 safe-icon-yellow",
            })
          ),
        ];
      },
    },
  ];
};

const TabExam = defineComponent({
  props: {
    exams: { type: Array as () => Array<Exam> },
  },
  methods: {
    weightFilter,
    dateFilter,
  },
  components: { NDataTable },
  data() {
    return { columns: createColumns() };
  },
})
export default TabExam;
