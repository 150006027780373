
import { AnimalFridge } from "@/models/animal/animal-fridge.model";
import {
  dateFilter,
  weightFilter,
} from "@/filters/custom-filters";
import { defineComponent } from "@vue/runtime-core";
import { PropType } from "vue";

const TabFridge = defineComponent({
  props: {
    fridge: Object as PropType<AnimalFridge>
  },
  methods: {
    dateFilter,
    weightFilter
  }
});

export default TabFridge;

